import React, {useState, useEffect} from 'react';
import validator from 'validator';
import { useNavigate } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Stack, TextField, Autocomplete, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { BodyText, BulletList, BannerNotification, Modal, PrimaryButton, SecondaryButton, ToggleSwitch, TextInput, HeadingSmall, FormLabel } from "@hm-group/fabric-components/brand/hm";
import { CloseIcon, PlusIcon } from "@hm-group/fabric-icons";

import instance from '../../services/UserService';
import PageContainer from '../../components/PageContainer';
import HMOnlineAccountSection from './Sections/HMOnlineAccountSection';

export default function AddContact({ selectedShowroomID, submenu }) {
	const [countryList, setCountryList] = useState([]);
	const [contactLabelList, setContactLabelList] = useState([]);
	const [contactLabel, setContactLabel] = useState([]);
	const [contactPurposeList, setContactPurposeList] = useState([]);
	const [contactPurpose, setContactPurpose] = useState('');
	const [interestedInList, setInterestedInList] = useState([]);
	const [interestedIn, setInterestedIn] = useState([]);
	const [mediaLabelList, setMediaLabelList] = useState([]);
	const [mediaLabel, setMediaLabel] = useState('');
	const [enableSave, setEnableSave] = useState(false);
	const [showErrorBar, setShowErrorBar] = React.useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [url, setUrl] = useState('');
	const [additionalInformation, setAdditionalInformation] = useState('');
	const [deliveryAddress, setDeliveryAddress] = useState('');
	const [deliveryAddress1, setDeliveryAddress1] = useState('');
	const [deliveryAddress2, setDeliveryAddress2] = useState('');
	const [deliveryAddress3, setDeliveryAddress3] = useState('');
	const [mailingAddress, setMailingAddress] = useState('');
	const [mailingAddress1, setMailingAddress1] = useState('');
	const [mailingAddress2, setMailingAddress2] = useState('');
	const [mailingAddress3, setMailingAddress3] = useState('');
	const [showPopUp, setShowPopUp] = useState(false);
	const [isEmailValid, setIsEmailValid] = React.useState(false);
	const [isPhoneNoValid, setIsPhoneNoValid] = React.useState(false);
	const [defaultCountryMailing, setDefaultCountryMailing] = useState('');
    const [defaultCountryDelivery, setDefaultCountryDelivery] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [showMailingAddressSection, setShowMailingAddressSection] = useState(false);
	const [showAddMailingAddressButton, setShowAddMailingAddressButton] = useState(true);
	const [error, setError] = useState();
	const [emailError, setEmailError] = useState();
	const [contactInfo, setContactInfo] = useState({ memberIDConsent: false });

	const navigate = useNavigate();
		
	const handleError = ({ isValidNumber }) => {
		if (!isValidNumber) {
			setError('Invalid Phone Number');
			setIsPhoneNoValid(false);
		} else {
			setError(undefined);
			setIsPhoneNoValid(true);
		}
	}

	const validateEmail = (input) => {
		var email = input;
	  
		if (validator.isEmail(email)) {
		  setEmailError(undefined);
		  setIsEmailValid(true);
		} else {
		  setEmailError('This email is not valid. Please enter a valid email (example@email.com)');
		  setIsEmailValid(false);
		}
	  }

	const saveContact = e   => {
		e.preventDefault();
		var contactDetails = {
			"firstName": firstName,
			"lastName": lastName,
			"contactPurposeId": contactPurpose,
			"contactlabelIdList": contactLabel,
			"email" : email,
			"phone" : phoneNumber,
			"intIdList":interestedIn ? interestedIn : '',
			"mediaId":mediaLabel,
			"additionalInfo":additionalInformation,
			"url":url,
			"deliveryAddressLine1":deliveryAddress,
			"deliveryAddressLine2":deliveryAddress1,
			"deliveryAddressLine3":deliveryAddress2,
            "deliveryAddressLine4":deliveryAddress3,
			"countryCodeDelivery":defaultCountryDelivery,
			"mailingAddressLine1":mailingAddress,
			"mailingAddressLine2":mailingAddress1,
			"mailingAddressLine3":mailingAddress2,
			"mailingAddressLine4":mailingAddress3,
			"countryCodeMailing":defaultCountryMailing,
			"showroomId":selectedShowroomID,
			"memberIDConsent": contactInfo.memberIDConsent,
      		"memberID": contactInfo.memberID,
		}

		instance
		.post('/contact/save',contactDetails, {
			"headers": {
				"content-type": "application/json",
				}
		})		
		.then(response => {
			navigate('/contacts/', { state: `The Contact <b>${firstName} ${lastName}</b> was successfully added! Please, inform the Contact that their information is saved.`});
		})
		.catch(error => {
			setShowErrorBar(true);
		})
	}

	const  onSelectingSameDeliveryAddress= (event, value) => {
		setIsChecked(!isChecked);
		setShowMailingAddressSection(true);
		if(!isChecked){
			instance.get("/getMediaDeliveryAddress/"+mediaLabel)
          .then(response => {
            setDeliveryAddress(response.data.delivery_address_line1);
			setDeliveryAddress1(response.data.delivery_address_line2);
            setDeliveryAddress2(response.data.delivery_address_line3);
            setDeliveryAddress3(response.data.delivery_address_line4);
            setDefaultCountryDelivery(response.data.country_code_delivery);
          })
		.catch(error => {
			console.log(error);
		});
	   } else {
			setDeliveryAddress('');
			setDeliveryAddress1('');
			setDeliveryAddress2('');
			setDeliveryAddress3('');
			setDefaultCountryDelivery('');
	   }
    }

	const  onSelectingSameMailingAddress= (event, value) => {
		if(!isChecked){
			instance.get("/getMediaMailingAddress/"+mediaLabel)
          .then(response => {
            setMailingAddress(response.data.mailing_address_line1);
			setMailingAddress1(response.data.mailing_address_line2);
            setMailingAddress2(response.data.mailing_address_line3);
            setMailingAddress3(response.data.mailing_address_line4);
            setDefaultCountryMailing(response.data.country_code_mailing);
          })
		.catch(error => {
			console.log(error);
		});
	   } else {
		setMailingAddress('');
		setMailingAddress1('');
		setMailingAddress2('');
		setMailingAddress3('');
		setDefaultCountryMailing('');
	   }
    }

	const validateIfContactExistOnSecondNameChange=(event, value)=>{
		instance
		.get('/checkExistContact/'+firstName+"/"+event+"/"+selectedShowroomID)
		.then(response => {
			if(response.data >= 1){
				setShowPopUp(true);
			} else {
				setShowPopUp(false);
			}
		})
		.catch(error => {
			console.log(error);
		})
	}

	const validateIfContactExistOnFirstNameChange=(event, value)=>{
		instance
		.get('/checkExistContact/'+event+"/"+lastName+"/"+selectedShowroomID)
		.then(response => {
			if(response.data >= 1){
				setShowPopUp(true);
			} else {
				setShowPopUp(false);
			}
		})
		.catch(error => {
			console.log(error);
		})
	}

		useEffect( () => {
			const loadCountries = async() => {
				const response = await instance.get("/countries");
				var resultCountry = response.data.map(eachCountry => ({ id:eachCountry.GeographicalCountryId, label:eachCountry.CountryOfDeliveryName}));
				setCountryList(resultCountry);
			};
			loadCountries();
		},[])

		useEffect( () => {
			const loadContactLabels = async() => {
				const response = await instance.get("/getContactLabels");
				var resultContactLabels = response.data.map(eachContactLabel => ({ value:eachContactLabel.contactLabelId, label:eachContactLabel.contactLabelName}));
				setContactLabelList(resultContactLabels);
			};
			loadContactLabels();
		},[])

		useEffect( () => {
			const loadContactPurposes = async() => {
				const response = await instance.get("/getContactPurpose");
				var resultContactPurpose = response.data.map(eachContactPurpose => ({ value:eachContactPurpose.contactPurposeId, label:eachContactPurpose.purposeName}));
				setContactPurposeList(resultContactPurpose);
			};
			loadContactPurposes();
		},[])

		useEffect( () => {
			const loadInterestedIns = async() => {
				const response = await instance.get("/getInterestedIn");
				var resultInterestedIn = response.data.map(eachInterestedIn => ({ value:eachInterestedIn.interestedInId, label:eachInterestedIn.interestedIn}));
				setInterestedInList(resultInterestedIn);
			};
			loadInterestedIns();
		},[])

		useEffect( () => {
			const loadMediaLabels = async() => {
				const response = await instance.get("/getMedias/"+selectedShowroomID);
				var resultMediaLabels = response.data.map(eachMediaLabel => ({ value:eachMediaLabel.mediaId, label:eachMediaLabel.mediaName}));
				setMediaLabelList(resultMediaLabels);
			};
			loadMediaLabels();
		},[selectedShowroomID])
	
	
	useEffect(() => {
		if (firstName && lastName && contactLabel.length>0 && contactPurpose && email && phoneNumber){
			// if(isEmailValid && isPhoneNoValid) {
			if(isEmailValid) {
				setEnableSave(true);
			} else{
				setEnableSave(false);
			}
		} else if(firstName && lastName && contactLabel.length>0 && contactPurpose && email){
			if(isEmailValid) {
				setEnableSave(true);
			} else{
				setEnableSave(false);
			}
		// } else if(firstName && lastName && contactLabel.length>0 && contactPurpose && phoneNumber){
				// if(isPhoneNoValid) {
				// 	setEnableSave(true);
				// } else{
				// 	setEnableSave(false);
				// }
		} else if(firstName && lastName && contactLabel.length>0 && contactPurpose){
			setEnableSave(true);
		} else {
			setEnableSave(false);
		}
	}, [firstName,lastName,contactLabel,contactPurpose, email]);

	const handleOnClickAdMailingSec = () => {
		setShowMailingAddressSection(true);
		setShowAddMailingAddressButton(false);
	}

	const handleOnClickRemoveButton = () => {
		setMailingAddress('');
		setMailingAddress1('');
		setMailingAddress2('');
		setMailingAddress3('');
		setDefaultCountryMailing('');

		setShowMailingAddressSection(false);
		setShowAddMailingAddressButton(true);
	}

	const onClickOfOk = e   => {
		setShowErrorBar(false);
	}
	
	return (
		<PageContainer title="Add Contact" submenu={submenu}>
			{showErrorBar && (
				<Modal
					isOpen={showErrorBar}
					heading="ERROR"
					content="Save Contact failed!"
					closeLabel="Close"
					size="medium"
					onClose={() => setShowErrorBar(!showErrorBar)}
					actions={<PrimaryButton onClick={onClickOfOk}>Ok</PrimaryButton>}
				/>
			)}

			<Grid container spacing={6}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
					<HeadingSmall>Contact information</HeadingSmall>

					<TextInput
						label="First name"
						name="firstName"
						value={firstName}
						isRequired
						onChange={e => {
							setFirstName(e.target.value);
							validateIfContactExistOnFirstNameChange(e.target.value);
						}}
					/>

					<TextInput
						label="Last name"
						name="lastName"
						value={lastName}
						isRequired
						onChange={e => {
							setLastName(e.target.value);
							validateIfContactExistOnSecondNameChange(e.target.value);
						}}
					/>

					{showPopUp && (
						<BannerNotification 
							severity="warning"
							message="This contact already exists. Please, make sure to create a unique Contact."
							closeLabel="Close"
							// onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>
						/>
					)}

					<TextInput
						label="Email"
						name="email"
						value={email}
						placeholder="example@email.com"
						inputMode="email"
						onChange={e => {
							setEmail(e.target.value);
							if (e.target.value.length > 0) {
								validateEmail(e.target.value);
							} else {
								setEmailError(undefined);
		  						setIsEmailValid(true);
							}
						}}
						isInvalid={emailError}
						helperText={emailError}
					/>

					<TextInput
						label="Phone Number"
						name="phoneNumber"
						value={phoneNumber}
						placeholder='e.g. +1234567890'
						inputMode="tel"
						onChange={e => setPhoneNumber(e.target.value)}
						helperText="Please enter a valid phone number. It should start with a '+' followed by country code and contain numbers only."
						// onErrorCallBack={handleError}
						// error={error}
					/>

					<TextInput
						label="URL"
						name="url"
						value={url}
						inputMode="url"
						onChange={e => setUrl(e.target.value)}
					/>

					<FormControl>
						<InputLabel id="mediaLabel-label">Media</InputLabel>
						<Select
							id="mediaLabel"
							label="Media"
							value={mediaLabel ? mediaLabel : ''}
							onChange={e => setMediaLabel(e.target.value)}
						>
							{mediaLabelList.map(mediaLabel => <MenuItem value={mediaLabel.value}>{mediaLabel.label}</MenuItem>)}
						</Select>
					</FormControl>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
					<HeadingSmall>Purpose and interests</HeadingSmall>

					<FormControl required>
						<InputLabel id="contact-purpose-select-label">Contact Purpose</InputLabel>
						<Select
							id="contact-purpose-select"
							label="Contact Purpose"
							value={contactPurpose ? contactPurpose : ''}
							onChange={e => setContactPurpose(e.target.value)}
						>
							{contactPurposeList.map(contactPurpose => <MenuItem value={contactPurpose.value}>{contactPurpose.label}</MenuItem>)}
						</Select>
						<FormHelperText>Due to GDPR reasons you need to fill in why the specific Contact is being registered. If the contact will lend samples, choose 'Loan'.
						If other collaboration, choose 'PR'.</FormHelperText>
					</FormControl>

					<div>
						<FormLabel>Contact occupation(s) <font color="red">*</font></FormLabel>
						<Autocomplete
							multiple
							classes={{
								option: 'hm-select-options-text'
							}}
							id="occupations-standard"
							value={contactLabel}
							options={contactLabelList.map((option) => option.label)}
							renderInput={(params) => (
							<TextField {...params} className="hm-text-field" required placeholder="-Choose contact occupation(s)-" />
							)}
							onChange={(event, value) => {
								setContactLabel(value);
							}}
						/>
					</div>

					<div>
						<FormLabel>Interested in</FormLabel>
						<Autocomplete 
							multiple
							classes={{
								option: 'hm-select-options-text'
							}}
							id="interestedIn-standard"
							value={interestedIn}
							options={interestedInList.map((option) => option.label)}
							renderInput={(params) => (
								<TextField {...params} className="hm-text-field" placeholder="-Choose interest(s)-" />
							)}
							onChange={(event, value) => {
								setInterestedIn(value);
							}}
						/>
					</div>
                </Stack>
              </Grid>
              <Grid item xs={4}>
			 	<HMOnlineAccountSection
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                />

                <Stack direction="column" spacing={2} marginTop={4}>
					<HeadingSmall>Additional information</HeadingSmall>

					<FormLabel>Additional information</FormLabel>
					<TextField
						style={{background: "white", marginTop: '4px'}}
						name="additionalInformation"
						multiline
						rows={5} cols={15}
						value={additionalInformation}
						onChange={(event, value) => {
							setAdditionalInformation(event.target.value);
						}}
					/>
					<BodyText>
						In Additional Info you are only allowed to write relevant facts from an objective point of view such as:
					</BodyText>
					<BulletList items={[
						'What the Contact writes about, focus areas etc.',
						'Who the Contact is styling, is working with, has been working with, favorite photographer etc.'
					]}/>
					<BodyText>Sensitive information or personal reflections are NOT allowed.</BodyText>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ paddingTop: '24px'}}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
					<HeadingSmall>Delivery information</HeadingSmall>

					<ToggleSwitch
						id="toggleswitch"
						isDisabled={!mediaLabel}
						onChange={() => {
							setShowAddMailingAddressButton(false);
							onSelectingSameDeliveryAddress();
							onSelectingSameMailingAddress();
						}}
						label="Use same as Media"
						isChecked={isChecked}
					/>

					<TextInput
						label="Delivery Address"
						name="deliveryAddress"
						value={deliveryAddress}
						onChange={e => setDeliveryAddress(e.target.value)}
					/>

					<TextInput
						label="Apartment,suite,etc."
						name="deliveryAddress1"
						value={deliveryAddress1}
						onChange={e => setDeliveryAddress1(e.target.value)}
					/>

					<TextInput
						label="Zip code"
						name="deliveryAddress2"
						value={deliveryAddress2}
						onChange={e => setDeliveryAddress2(e.target.value)}
					/>

					<TextInput
						label="City"
						name="deliveryAddress3"
						value={deliveryAddress3}
						onChange={e => setDeliveryAddress3(e.target.value)}
					/>

					<FormControl>
						<InputLabel id="defaultCountryDelivery-label">Country</InputLabel>
						<Select
							id="defaultCountryDelivery"
							label="Country"
							value={defaultCountryDelivery ? defaultCountryDelivery : ''}
							onChange={e => setDefaultCountryDelivery(e.target.value)}
						>
							{countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
						</Select>
					</FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
					<HeadingSmall>Mailing information</HeadingSmall>

					{showAddMailingAddressButton && (
						<> 
							<SecondaryButton
								onClick={handleOnClickAdMailingSec}
								iconElement={<PlusIcon size='small'/>}
							>
								Add Mailing information
							</SecondaryButton>
							<BodyText>If other than delivery address</BodyText>
						</>
					)}

					{showMailingAddressSection && (
						<>
							<SecondaryButton
								onClick={handleOnClickRemoveButton}
								iconElement={<CloseIcon size="small" />}
							>
								Remove Mailing information
							</SecondaryButton>

							<TextInput
								label="Mailing Address"
								name="mailingAddress"
								value={mailingAddress}
								onChange={e => setMailingAddress(e.target.value)}
							/>

							<TextInput
								label="Apartment,suite,etc."
								name="mailingAddress1"
								value={mailingAddress1}
								onChange={e => setMailingAddress1(e.target.value)}
							/>

							<TextInput
								label="Zip code'"
								name="mailingAddress2"
								value={mailingAddress2}
								onChange={e => setMailingAddress2(e.target.value)}
							/>

							<TextInput
								label="City"
								name="mailingAddress3"
								value={mailingAddress3}
								onChange={e => setMailingAddress3(e.target.value)}
							/>

							<FormControl>
								<InputLabel id="defaultCountryMailing-label">Country</InputLabel>
								<Select
									id="defaultCountryMailing"
									label="Country"
									value={defaultCountryMailing ? defaultCountryMailing : ''}
									onChange={e => setDefaultCountryMailing(e.target.value)}
								>
									{countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
								</Select>
							</FormControl>
						</>
					)}
                </Stack>
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2} paddingTop="48px">
				<PrimaryButton 
					onClick={saveContact}
					isDisabled={!enableSave || showPopUp}
					type="submit"
				>
					Save Contact
				</PrimaryButton>
            </Stack>
		</PageContainer>
	)
}
