import {React, useState, useEffect} from 'react';
import validator from 'validator';
import { useParams, useNavigate } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Stack, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText  } from '@mui/material';
import { HeadingSmall, FormLabel, BodyText, BulletList, Modal, PrimaryButton, SecondaryButton, BannerNotification, TextInput } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';
import PageContainer from '../../components/PageContainer';
import HMOnlineAccountSection from './Sections/HMOnlineAccountSection';

export default function ContactInfo({ selectedShowroomID, submenu }) {
  const [shouldHideContactInfo, setShouldHideContactInfo] = useState();
  const [contactInfo, setContactInfo] = useState({});
  const [initialContactInfo, setInitialContactInfo] = useState({});
  const [showOldContactInfo, setShowOldContactInfo] = useState();
  const [contactLabelList, setContactLabelList] = useState([]);
	const [contactPurposeList, setContactPurposeList] = useState([]);
  const [interestedInList, setInterestedInList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [medias, setMedias] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);
  const [showWarningContactNameExists, setShowWarningContactNameExists] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUpdateContactSuccessModal, setShowUpdateContactSuccessModal] = useState(false);
  const [showContactDeletionFailure, setShowContactDeletionFailure] = useState(false);
  const [showDeleteContactConfirmationModal, setShowDeleteContactConfirmationModal] = useState(false);
  const [showUpdateAddressConfirmationModal, setShowUpdateAddressConfirmationModal] = useState(false);

  const {contactID} = useParams();
  const navigate = useNavigate();

  const convertToArray = (value) => {
    let newValue = [];

    if (value) {
      if (value[value.length - 1] === '&') {
        newValue = value.slice(0, - 1).split('&');
      } else {
        newValue = value.split('&');
      }
    }

    return newValue;
  }

  useEffect( () => {
    instance.get('/contact/getAllContacts', {
      params: {
        'contactId': contactID,
      }
    }).then(response => {
      setInitialContactInfo({
        firstName: response.data.content[0].firstName,
        lastName: response.data.content[0].lastName,
        email: response.data.content[0].email,
        phone: response.data.content[0].phone,
        url: response.data.content[0].url,
        defaultContactPur: response.data.content[0].contactPurposeId,
        defaultContactLabel: convertToArray(response.data.content[0].contactlabelId),
        defaultInterestedInId: convertToArray(response.data.content[0].interestedInId),
        defaultMediaId: (response.data.mediaId === null) ? '' : response.data.content[0].mediaId,
        additionalInformation: response.data.content[0].additionalInfo,
        deliveryAddress: response.data.content[0].deliveryAddressLine1,
        deliveryAddress1: response.data.content[0].deliveryAddressLine2,
        deliveryAddress2: response.data.content[0].deliveryAddressLine3,
        deliveryAddress3: response.data.content[0].deliveryAddressLine4,
        defaultCountryDelivery: response.data.content[0].countryCodeDelivery,
        mailingAddress: response.data.content[0].mailingAddressLine1,
        mailingAddress1: response.data.content[0].mailingAddressLine2,
        mailingAddress2: response.data.content[0].mailingAddressLine3,
        mailingAddress3: response.data.content[0].mailingAddressLine4,
        defaultCountryMailing: response.data.content[0].countryCodeMailing,
        showroomID: response.data.content[0].showroomId,
        memberIDConsent: response.data.content[0].memberIDConsent || false,
        memberID: response.data.content[0].memberID,
      });

      const todaysDate = new Date(response.headers.date);
      let lastUpdateDate;

      if (response.data.content[0].updatedDate) {
        lastUpdateDate = new Date(response.data.content[0].updatedDate);
      } else {
        lastUpdateDate = new Date(response.data.content[0].createdDate);
      }

      const daysDifference = (todaysDate.getTime() - lastUpdateDate.getTime()) / (1000*60*60*24);

      if(daysDifference > 365) {
        setShowOldContactInfo(true);
      }
    })
    .catch(error => {
      console.log(error);
    })
  },[contactID])

  useEffect( () => {
    setContactInfo(initialContactInfo);
  },[initialContactInfo])

  // On component load
  useEffect( () => {
    const getContactPurposes = async() => {
      const response = await instance.get("/getContactPurpose");
      setContactPurposeList(response.data.map(purpose => ({ value: purpose.contactPurposeId, label: purpose.purposeName})));
    };

    const getContactLabels = async() => {
      const response = await instance.get("/getContactLabels");
      setContactLabelList(response.data.map(contactLabel => ({ value: contactLabel.contactLabelId, label: contactLabel.contactLabelName})));
    };

    const getInterestedIns = async() => {
      const response = await instance.get("/getInterestedIn");
      setInterestedInList(response.data.map(interestedIn => ({ value: interestedIn.interestedInId, label: interestedIn.interestedIn})));
    };

    const getCountries = async() => {
      const response = await instance.get("/countries");
      setCountryList(response.data.map(country => ({ id: country.GeographicalCountryId, label: country.CountryOfDeliveryName})));
    };

    getContactPurposes();
    getContactLabels();
    getCountries();
    getInterestedIns();
  },[])

  useEffect( () => {
    const getMedias = async() => {
      const response = await instance.get("/getMedias/"+selectedShowroomID);
      setMedias(response.data.map(media => ({ value: media.mediaId, label: media.mediaName})));
    };

    getMedias();
  },[selectedShowroomID])

  useEffect( () => {
    if (initialContactInfo.showroomID && initialContactInfo.showroomID.toString() === selectedShowroomID.toString()) {
      setShouldHideContactInfo(false);
    } else {
      setShouldHideContactInfo(true);
    }
  },[initialContactInfo.showroomID, selectedShowroomID])

  const validatePhoneNumber = ({ isValidNumber }) => {
		if (contactInfo.phone && !isValidNumber) {
			setPhoneNumberError('Invalid Phone Number');
		} else {
			setPhoneNumberError(undefined);
		}
	}

  useEffect( () => {
    if ((contactInfo.firstName !== initialContactInfo.firstName) || (contactInfo.lastName !== initialContactInfo.lastName)) {
      instance
      .get('/checkExistContact/'+contactInfo.firstName+"/"+contactInfo.lastName+"/"+selectedShowroomID)
      .then(response => {
        if(response.data >= 1){
          setShowWarningContactNameExists(true);
        } else {
          setShowWarningContactNameExists(false);
        }
      })
      .catch(error => {
        console.log(error);
      })
    }
  },[contactInfo.firstName, contactInfo.lastName])

  useEffect( () => {
    if(JSON.stringify(contactInfo) !== JSON.stringify(initialContactInfo) && !phoneNumberError && !showWarningContactNameExists && !emailError) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  },[contactInfo, phoneNumberError, emailError, showWarningContactNameExists])

  const saveContactChanges = () => {
		const contactDetails = {
			"firstName": contactInfo.firstName,
			"lastName": contactInfo.lastName,
			"email": contactInfo.email,
			"phone": contactInfo.phone,
			"additionalInfo": contactInfo.additionalInformation,
			"url": contactInfo.url,
      "contactPurposeId": contactInfo.defaultContactPur,
      "contactlabelIdList": contactInfo.defaultContactLabel,
      "mediaId": contactInfo.defaultMediaId,
      "intIdList": contactInfo.defaultInterestedInId ? contactInfo.defaultInterestedInId : '',
			"deliveryAddressLine1": contactInfo.deliveryAddress,
			"deliveryAddressLine2": contactInfo.deliveryAddress1,
			"deliveryAddressLine3": contactInfo.deliveryAddress2,
      "deliveryAddressLine4": contactInfo.deliveryAddress3,
			"countryCodeDelivery": contactInfo.defaultCountryDelivery,
			"mailingAddressLine1": contactInfo.mailingAddress,
			"mailingAddressLine2": contactInfo.mailingAddress1,
			"mailingAddressLine3": contactInfo.mailingAddress2,
			"mailingAddressLine4": contactInfo.mailingAddress3,
			"countryCodeMailing": contactInfo.defaultCountryMailing,
      "contactId": contactID,
      "memberIDConsent": contactInfo.memberIDConsent,
      "memberID": contactInfo.memberID,
		}

    instance.put('/contact/update', contactDetails, {
      "headers": {
        "content-type": "application/json",
      }
    })
    .then(response => {
      setShowUpdateContactSuccessModal(true);
      setShowOldContactInfo(false);
      setInitialContactInfo(contactInfo);
    })
    .catch(error => {
      setShowErrorModal(true);
    })
  }

  const deleteContact = () => {
    setShowDeleteContactConfirmationModal(false);

    instance.post('/deleteContact/' + contactID)
    .then(response => {
      if(response.data === 'Exist'){
        setShowContactDeletionFailure(true);
      } else {
        navigate('/contacts/', { state: `Contact <b>${initialContactInfo.firstName} ${initialContactInfo.lastName}</b> was successfully deleted.`});
      }
    })
    .catch(error => {
      setShowErrorModal(true);
    })
  }

  const updateDeliveryAndMailingInformation = mediaID => {
    instance.get('/media/getAllMedias', {
      params: {
        'mediaId': mediaID,
      }
    }).then(response => {
      setContactInfo({
        ...contactInfo,
        deliveryAddress: response.data.content[0].deliveryAddressLine1,
        deliveryAddress1: response.data.content[0].deliveryAddressLine2,
        deliveryAddress2: response.data.content[0].deliveryAddressLine3,
        deliveryAddress3: response.data.content[0].deliveryAddressLine4,
        defaultCountryDelivery: response.data.content[0].countryCodeDelivery,
        mailingAddress: response.data.content[0].mailingAddressLine1,
        mailingAddress1: response.data.content[0].mailingAddressLine2,
        mailingAddress2: response.data.content[0].mailingAddressLine3,
        mailingAddress3: response.data.content[0].mailingAddressLine4,
        defaultCountryMailing: response.data.content[0].countryCodeMailing,
      })
    })
    .catch(error => {
      console.log(error);
    });
    
    setShowUpdateAddressConfirmationModal(false);
  }
  
  if (initialContactInfo.showroomID) {
    return (
      <PageContainer title={shouldHideContactInfo ? 'Invalid contact' : `Contact: ${initialContactInfo.firstName} ${initialContactInfo.lastName}`} submenu={submenu}>
        {shouldHideContactInfo && (
          <div>This Contact is not connected to your showroom.</div>
        )}

        {showUpdateAddressConfirmationModal && (
          <Modal
            isOpen={showUpdateAddressConfirmationModal}
            heading="Update Delivery & Mailing information"
            content={
              <BodyText dangerouslySetInnerHTML={{__html: `Do you want to overwrite Contact's current Delivery information and Mailing information with the one from ${contactInfo.defaultMediaName}?`}}/>
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowUpdateAddressConfirmationModal(false)}
            actions={
              <>
                <PrimaryButton onClick={() => updateDeliveryAndMailingInformation(contactInfo.defaultMediaId)}>Yes</PrimaryButton>
                <SecondaryButton onClick={() => setShowUpdateAddressConfirmationModal(false)}>No</SecondaryButton>
              </>
            }
          />
        )}

        {showDeleteContactConfirmationModal && (
          <Modal
            isOpen={showDeleteContactConfirmationModal}
            heading="Delete Contact"
            content={
              <BodyText dangerouslySetInnerHTML={{__html: `Are you sure you want to delete the Contact <b>${contactInfo.firstName} ${contactInfo.lastName}</b>?`}}/>
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowDeleteContactConfirmationModal(false)}
            actions={
              <>
                <PrimaryButton onClick={() => deleteContact()}>Yes</PrimaryButton>
                <SecondaryButton onClick={() => setShowDeleteContactConfirmationModal(false)}>No</SecondaryButton>
              </>
            }
          />
        )}

        {showContactDeletionFailure && (
          <Modal
            isOpen={showContactDeletionFailure}
            heading="Can't delete Contact"
            content={
              <BodyText dangerouslySetInnerHTML={{__html: `The Contact <b>${contactInfo.firstName} ${contactInfo.lastName}</b> can't be deleted since they have unreturned loans.`}}/>
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowContactDeletionFailure(false)}
          />
        )}

        {showUpdateContactSuccessModal && (
          <Modal
            isOpen={showUpdateContactSuccessModal}
            heading="Changes Saved"
            content={
              <BodyText dangerouslySetInnerHTML={{__html: `The Contact <b>${contactInfo.firstName} ${contactInfo.lastName}</b> was successfully updated!`}}/>
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowUpdateContactSuccessModal(false)}
          />
        )}

        {showErrorModal && (
          <Modal
            isOpen={showErrorModal}
            heading="FAILURE"
            content="Internal Server Error Occurred!"
            closeLabel="Close"
            size="medium"
            onClose={() => setShowErrorModal(false)}
          />
        )}

        {showOldContactInfo && (
          <BannerNotification 
            severity="warning"
            message="The information of this contact has not been updated for more than a year. Make sure it's accurate, then save."
            closeLabel="Close"
          />
        )}
        
        {!shouldHideContactInfo && (
          <>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <HeadingSmall>Contact Information</HeadingSmall>

                  <TextInput
                    label="First name"
                    name="firstName"
                    value={contactInfo.firstName}
                    isRequired
                    onChange={e => setContactInfo({...contactInfo, firstName: e.target.value})}
                  />

                  <TextInput
                    label="Last name"
                    name="lastName"
                    value={contactInfo.lastName}
                    isRequired
                    onChange={e => setContactInfo({...contactInfo, lastName: e.target.value})}
                  />
      
                  {showWarningContactNameExists && (
                    <BannerNotification 
                      severity="warning"
                      message="A contact with this name already exists. Please make sure to create a unique Contact."
                      closeLabel="Close"
						        />
                  )}

                  <TextInput
                    label="Email"
                    name="email"
                    value={contactInfo.email}
                    placeholder="example@email.com"
                    inputMode="email"
                    onChange={e => {
                      setContactInfo({...contactInfo, email: e.target.value});

                      if (e.target.value.length > 0 && !validator.isEmail(e.target.value)) {
                        setEmailError('This email format is not valid. Please enter a valid email format (example@email.com)')
                      } else {
                        setEmailError(undefined)
                      }
                    }}
                    isInvalid={emailError}
                    helperText={emailError}
                  />

                  <TextInput
                    label="Phone Number"
                    name="phoneNumber"
                    value={contactInfo.phone}
                    placeholder='e.g. +1234567890'
                    inputMode="tel"
                    onChange={e => setContactInfo({...contactInfo, phone: e.target.value})}
                    helperText="Please enter a valid phone number. It should start with a '+' followed by country code and contain numbers only."
                    // onErrorCallBack={validatePhoneNumber}
                    // error={phoneNumberError}
                  />

                  <TextInput
                    label="URL"
                    name="url"
                    value={contactInfo.url}
                    inputMode="url"
                    onChange={e => setContactInfo({...contactInfo, url: e.target.value})}
                  />

                  <FormControl>
                    <InputLabel id="mediaLabel-label">Media</InputLabel>
                    <Select
                      id="mediaLabel"
                      label="Media"
                      value={contactInfo.defaultMediaId || ''}
                      onChange={e => {
                        const chosenMediaObj = medias.find(media => media.value === e.target.value);
                        setContactInfo({
                          ...contactInfo, 
                          defaultMediaId: e.target.value,
                          defaultMediaName: chosenMediaObj.label,
                        });
                        setShowUpdateAddressConfirmationModal(true);
                      }}
                    >
                      {medias.map(media => <MenuItem key={media.value} value={media.value}>{media.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <HeadingSmall>Purpose and interests</HeadingSmall>

                  <FormControl required>
                    <InputLabel id="contactPurpose-label">Contact Purpose</InputLabel>
                    <Select
                      id="contactPurpose"
                      label="Contact Purpose"
                      value={contactInfo.defaultContactPur || ''}
                      onChange={e => setContactInfo({...contactInfo, defaultContactPur: e.target.value})}
                    >
                      {contactPurposeList.map(contactPurpose => <MenuItem key={contactPurpose.value} value={contactPurpose.value}>{contactPurpose.label}</MenuItem>)}
                    </Select>
                    <FormHelperText>Due to GDPR reasons you need to fill in why the specific Contact is being registered. If the contact will lend samples, choose 'Loan'.
                    If other collaboration, choose 'PR'.</FormHelperText>
                  </FormControl>

                  <div>
                    <FormLabel>Contact occupation(s) <font color="red">*</font></FormLabel>
      
                    {contactInfo.defaultContactLabel && (
                      <Autocomplete
                        multiple
                        classes={{
                          option: 'hm-select-options-text'
                        }}
                        id="occupations-standard"
                        value={contactInfo.defaultContactLabel}
                        options={contactLabelList.map((option) => option.label)}
                        renderInput={(params) => (
                        <TextField {...params} className="hm-text-field" required placeholder="-Choose contact occupation(s)-" />
                        )}
                        onChange={(event, newValue) => setContactInfo({...contactInfo, defaultContactLabel: newValue})}
                      />
                    )}
                  </div>
      
                  <div>
                    <FormLabel>Interested in</FormLabel>

                    {contactInfo.defaultInterestedInId && (
                      <Autocomplete
                        multiple
                        classes={{
                          option: 'hm-select-options-text'
                        }}
                        id="interestedIn-standard"
                        value={contactInfo.defaultInterestedInId}
                        options={interestedInList.map((option) => option.label)}
                        renderInput={(params) => (
                        <TextField {...params} className="hm-text-field" required placeholder="-Choose interest(s)-" />
                        )}
                        onChange={(event, newValue) => setContactInfo({...contactInfo, defaultInterestedInId: newValue})}       
                      />
                    )}
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={4} spacing={2}>
                <HMOnlineAccountSection
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                />
                
                <Stack direction="column" spacing={2} marginTop={4}>
                  <HeadingSmall>Additional information</HeadingSmall>

                  <FormLabel>Additional information</FormLabel>

                  <TextField
                    style={{background: "white", marginTop: '4px'}}
                    name="additionalInformation"
                    multiline
                    rows={5} cols={15}
                    value={contactInfo.additionalInformation}
                    onChange={e => setContactInfo({...contactInfo, additionalInformation: e.target.value})}
                  />

                  <BodyText>
                    In Additional Info you are only allowed to write relevant facts from an objective point of view such as:
                  </BodyText>

                  <BulletList items={[
                    'What the Contact writes about, focus areas etc.',
                    'Who the Contact is styling, is working with, has been working with, favorite photographer etc.'
                  ]}/>

                  <BodyText>Sensitive information or personal reflections are NOT allowed.</BodyText>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ paddingTop: '24px'}}>
              <Grid item xs={4}>
              <Stack direction="column" spacing={2}>
                  <HeadingSmall>Delivery information</HeadingSmall>

                  <TextInput
                    label="Delivery Address"
                    name="deliveryAddress"
                    value={contactInfo.deliveryAddress}
                    onChange={e => setContactInfo({...contactInfo, deliveryAddress: e.target.value})}
                  />

                  <TextInput
                    label="Apartment,suite,etc."
                    name="deliveryAddress1"
                    value={contactInfo.deliveryAddress1}
                    onChange={e => setContactInfo({...contactInfo, deliveryAddress1: e.target.value})}
                  />

                  <TextInput
                    label="Zip code"
                    name="deliveryAddress2"
                    value={contactInfo.deliveryAddress2}
                    onChange={e => setContactInfo({...contactInfo, deliveryAddress2: e.target.value})}
                  />

                  <TextInput
                    label="City"
                    name="deliveryAddress3"
                    value={contactInfo.deliveryAddress3}
                    onChange={e => setContactInfo({...contactInfo, deliveryAddress3: e.target.value})}
                  />

                  <FormControl>
                    <InputLabel id="defaultCountryDelivery-label">Country</InputLabel>
                    <Select
                      id="defaultCountryDelivery"
                      label="Country"
                      value={contactInfo.defaultCountryDelivery || ''}
                      onChange={e => setContactInfo({...contactInfo, defaultCountryDelivery: e.target.value})}
                    >
                      {countryList.map(country => <MenuItem key={country.id} value={country.label}>{country.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
              <Stack direction="column" spacing={2}>
                  <HeadingSmall>Mailing information</HeadingSmall>
      
                  <TextInput
                    label="Mailing Address"
                    name="mailingAddress"
                    value={contactInfo.mailingAddress}
                    onChange={e => setContactInfo({...contactInfo, mailingAddress: e.target.value})}
                  />

                  <TextInput
                    label="Apartment,suite,etc."
                    name="mailingAddress1"
                    value={contactInfo.mailingAddress1}
                    onChange={e => setContactInfo({...contactInfo, mailingAddress1 : e.target.value})}
                  />

                  <TextInput
                    label="Zip code'"
                    name="mailingAddress2"
                    value={contactInfo.mailingAddress2}
                    onChange={e => setContactInfo({...contactInfo, mailingAddress2: e.target.value})}
                  />

                  <TextInput
                    label="City"
                    name="mailingAddress3"
                    value={contactInfo.mailingAddress3}
                    onChange={e => setContactInfo({...contactInfo, mailingAddress3: e.target.value})}
                  />

                  <FormControl>
                    <InputLabel id="defaultCountryMailing-label">Country</InputLabel>
                    <Select
                      id="defaultCountryMailing"
                      label="Country"
                      value={contactInfo.defaultCountryMailing || ''}
                      onChange={e => setContactInfo({...contactInfo, defaultCountryMailing: e.target.value})}
                    >
                      {countryList.map(country => <MenuItem key={country.id} value={country.label}>{country.label}</MenuItem>)}
                    </Select>
                  </FormControl>

                </Stack>
              </Grid>
            </Grid>   
      
            <Stack direction="row" spacing={2} paddingTop="48px">
              <PrimaryButton
                onClick={saveContactChanges}
                isDisabled={disableSaveButton}
              >
                Save Changes
              </PrimaryButton>
              <SecondaryButton 
                onClick={() => setShowDeleteContactConfirmationModal(true)}
              >
                Delete Contact
              </SecondaryButton>
            </Stack>
          </>	
        )}
      </PageContainer>
    )
  } else {
    return null
  }
}
